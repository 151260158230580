"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fixedNum = void 0;
const fixedNum = (params) => {
    return async () => {
        const { num, addresses, account } = params;
        if (addresses)
            return addresses.find(a => a.toLowerCase() == account.toLowerCase()) ? num : 0;
        return num;
    };
};
exports.fixedNum = fixedNum;
