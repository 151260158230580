"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minimumBalance = void 0;
const ethers_1 = require("ethers");
/**
 * Checks for minimum ETH balance
 */
const minimumBalance = (params) => {
    return async () => {
        const { account, provider, minEthBal } = params;
        const balance = await provider.getBalance(account);
        const balanceInEth = ethers_1.ethers.utils.formatEther(balance);
        if (parseFloat(balanceInEth) < minEthBal)
            return 0;
        return 1;
    };
};
exports.minimumBalance = minimumBalance;
