"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fixedVotes = void 0;
/**
 * Allocates `numVotes` votes to addresses
 */
const fixedVotes = (numVotes, addresses) => {
    return async (userAddress, communityAddress, blockTag, provider) => {
        const valid = addresses.find(a => a.toLowerCase() == userAddress.toLowerCase());
        return valid ? numVotes : 0;
    };
};
exports.fixedVotes = fixedVotes;
