"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lilNouns = void 0;
const client_1 = require("@apollo/client");
const nounishDelegatedVotesToAddressQuery_1 = require("../queries/nounishDelegatedVotesToAddressQuery");
const lilNounsSubgraphApiUri_1 = require("../constants/lilNounsSubgraphApiUri");
/**
 * Total delegated votes for address
 */
const lilNouns = (multiplier = 1) => {
    return async (userAddress, communityAddress, blockTag) => {
        const query = (0, client_1.gql)((0, nounishDelegatedVotesToAddressQuery_1.nounishDelegatedVotesToAddressQuery)(userAddress.toLocaleLowerCase(), blockTag)).loc?.source.body;
        const response = await fetch(lilNounsSubgraphApiUri_1.lilNounsSubgraphApiUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query,
            }),
        });
        const result = await response.json();
        if (result.errors) {
            throw new Error(result.errors[0].message);
        }
        const parsedResult = result.data.delegates[0] ? result.data.delegates[0].delegatedVotesRaw : 0;
        return parsedResult * multiplier;
    };
};
exports.lilNouns = lilNouns;
