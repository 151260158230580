"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.perWalletVoteErc20 = void 0;
const balanceOfErc20_1 = require("./balanceOfErc20");
/**
 * Gives a pre-specified number of `votes` to any wallet that has >= `threshold` amount of specified ERC20 token
 */
const perWalletVoteErc20 = (decimals, votes = 1, threshold = 1) => {
    return async (userAddress, communityAddress, blockTag, provider) => {
        const tokenBalance = await (0, balanceOfErc20_1.balanceOfErc20)(decimals)(userAddress, communityAddress, blockTag, provider);
        return tokenBalance >= threshold ? votes : 0;
    };
};
exports.perWalletVoteErc20 = perWalletVoteErc20;
