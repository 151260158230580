"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneHundredVotes = void 0;
/**
 * Allocates 100 votes to all users (to be used for development purposes)
 */
const oneHundredVotes = () => {
    return async (userAddress, communityAddress, blockTag, provider) => 100;
};
exports.oneHundredVotes = oneHundredVotes;
