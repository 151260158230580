"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.execStrategy = exports.getRelevantComms = exports.getVotingPower = exports.getName = void 0;
var getName_1 = require("./getName");
Object.defineProperty(exports, "getName", { enumerable: true, get: function () { return getName_1.getName; } });
var getVotingPower_1 = require("./getVotingPower");
Object.defineProperty(exports, "getVotingPower", { enumerable: true, get: function () { return getVotingPower_1.getVotingPower; } });
var getRelevantComms_1 = require("./getRelevantComms");
Object.defineProperty(exports, "getRelevantComms", { enumerable: true, get: function () { return getRelevantComms_1.getRelevantComms; } });
var execStrategy_1 = require("./execStrategy");
Object.defineProperty(exports, "execStrategy", { enumerable: true, get: function () { return execStrategy_1.execStrategy; } });
