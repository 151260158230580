"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nounishDelegatedVotesToAddressQuery = void 0;
const parseBlockTag_1 = require("../utils/parseBlockTag");
const nounishDelegatedVotesToAddressQuery = (address, blockTag) => {
    const parsedBlockTag = (0, parseBlockTag_1.parseBlockTag)(blockTag);
    return `
  {
    delegates(
      ${parsedBlockTag ? `block: { number: ${parsedBlockTag} },` : ''}
      where: { id: "${address}" }
    ) {
      delegatedVotesRaw
    }
  }
`;
};
exports.nounishDelegatedVotesToAddressQuery = nounishDelegatedVotesToAddressQuery;
