"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._strategies = exports.StrategyNames = void 0;
const balanceOfErc721_1 = require("./balanceOfErc721");
const balanceOfErc20_1 = require("./balanceOfErc20");
const fixedNum_1 = require("./fixedNum");
const validEnsAndMinBal_1 = require("./validEnsAndMinBal");
const thankApe_1 = require("./thankApe");
const minimumBalance_1 = require("./minimumBalance");
const balanceOfErc1155_1 = require("./balanceOfErc1155");
const nounsDelegatedVotes_1 = require("./nounsDelegatedVotes");
const kiwi_1 = require("./kiwi");
exports.StrategyNames = [
    'balanceOfErc721',
    'balanceOfErc20',
    'fixedNum',
    'validEnsAndMinBal',
    'thankApe',
    'minimumBalance',
    'balanceOfErc1155',
    'nounsDelegatedVotes',
    'kiwi',
];
exports._strategies = {
    balanceOfErc721: balanceOfErc721_1.balanceOfErc721,
    balanceOfErc20: balanceOfErc20_1.balanceOfErc20,
    fixedNum: fixedNum_1.fixedNum,
    validEnsAndMinBal: validEnsAndMinBal_1.validEnsAndMinBal,
    thankApe: thankApe_1.thankApe,
    minimumBalance: minimumBalance_1.minimumBalance,
    balanceOfErc1155: balanceOfErc1155_1.balanceOfErc1155,
    nounsDelegatedVotes: nounsDelegatedVotes_1.nounsDelegatedVotes,
    kiwi: kiwi_1.kiwi,
};
