"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.execStrategy = void 0;
const _strategies_1 = require("../_strategies");
const getStrategy_1 = require("../utils/getStrategy");
const execStrategy = async (args) => {
    if (!_strategies_1.StrategyNames.includes(args.strategyName))
        throw new Error(`No strategy found for strategy name ${args.strategyName}`);
    const strategy = (0, getStrategy_1.getStrategy)(args.strategyName)(args);
    try {
        return await strategy();
    }
    catch (e) {
        throw new Error(`Error executing strategy ${args.strategyName}: ${e}`);
    }
};
exports.execStrategy = execStrategy;
