"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStrategy = void 0;
const _strategies_1 = require("../_strategies");
/**
 * Resolve a strategy for validation or execution, if developing against
 * a specific strategy, select using the mapped object _strategies.
 */
const getStrategy = (strategyName) => {
    return _strategies_1._strategies[strategyName];
};
exports.getStrategy = getStrategy;
